import React from 'react'
import { Helmet } from 'react-helmet'
import Container from '@material-ui/core/Container'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import {
	StylesProvider,
	ThemeProvider as MuiThemeProvider,
	createMuiTheme,
} from '@material-ui/core/styles'
import Footer from './Footer'
import Header from './Header'
import FillWindow from '../../components/FillWindow'

const GlobalStyle = createGlobalStyle`
	body, html {
		min-height: 100vh;
	}
`

const defaultTheme = createMuiTheme()

const root = ({ children }) => (
	<StylesProvider injectFirst>
		<MuiThemeProvider theme={defaultTheme}>
			<ThemeProvider theme={defaultTheme}>
				<Helmet>
					<title>Thomas Hunter&apos;s Portfolio</title>
				</Helmet>
				<CssBaseline />
				<GlobalStyle />
				<FillWindow>
					<Grid
						style={{ minHeight: '100vh' }}
						container
						direction='column'
						justify='space-between'
					>
						<Grid item>
							<Container maxWidth='lg'>
								<Header />
								<main>{children}</main>
							</Container>
						</Grid>
						<Grid item>
							<footer>
								<Footer />
							</footer>
						</Grid>
					</Grid>
				</FillWindow>
			</ThemeProvider>
		</MuiThemeProvider>
	</StylesProvider>
)

export default root
