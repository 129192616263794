import React from 'react'
import styled from 'styled-components'
import { Grid, Typography, Toolbar } from '@material-ui/core'
import LogoSvg from '../../images/logo.svg'

const Title = styled(Typography)`
	flex: 1;
`

const Logo = styled(LogoSvg)`
	height: 3em;
	width: auto;
	display: block;
`

export default function Header() {
	return (
		<Toolbar disableGutters>
			<Title component='h2' variant='h5' color='inherit' noWrap>
				<Grid container direction='row' alignItems='center'>
					<Grid item>
						<Logo />
					</Grid>
					<Grid item>
						<Grid item>Thomas</Grid>
						<Grid item>Hunter</Grid>
					</Grid>
				</Grid>
			</Title>
		</Toolbar>
	)
}
