import styled from 'styled-components'

const FillWindow = styled.div`
	position: absolute;
	min-height: 100vh;
	left: 0;
	min-width: 100vw;
	overflow: hidden;
`

export default FillWindow
