import React from 'react'
import Grid from '@material-ui/core/Grid'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import GitHubIcon from '@material-ui/icons/GitHub'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'

const Social = ({ label, href, icon }) => (
	<Link target='_blank' href={href}>
		<Grid container direction='row'>
			<Grid item>
				<Box mr={1}>{icon}</Box>
			</Grid>
			<Grid item>{label}</Grid>
		</Grid>
	</Link>
)

Social.propTypes = {
	label: PropTypes.string.isRequired,
	href: PropTypes.string.isRequired,
	icon: PropTypes.element.isRequired,
}

export default function Footer() {
	return (
		<Container maxWidth='lg'>
			<Grid container alignItems='flex-start' spacing={2}>
				<Grid item sm={9}>
					👋 Software Engineer based in Leicestershire.
					{/* TODO update this. */}
				</Grid>
				<Grid item sm={3} container direction='column'>
					<Grid item>
						<Social
							label='LinkedIn'
							icon={<LinkedInIcon />}
							href='https://www.linkedin.com/in/thunter2/'
						/>
					</Grid>
					<Grid item>
						<Social
							label='GitHub'
							icon={<GitHubIcon />}
							href='https://github.com/thunter1000'
						/>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	)
}
